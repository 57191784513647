import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  Mutation,
  MutationUpdatePropertyUnitArgs,
  PropertyUnit,
  Query,
  QueryListPropertyUnitArgs,
  Tenant,
  TenantCreateInput,
} from '../generated';
import { RcApiService } from './rc-api.service';

@Injectable()
export class PropertyUnitService {
  constructor(private rcApiService: RcApiService) {}

  public listPropertyUnits(
    args: QueryListPropertyUnitArgs = {},
  ): Observable<PropertyUnit[]> {
    return this.rcApiService.apollo
      .query<Query>({
        query: gql`
          query ListPropertyUnits {
            listPropertyUnit {
              id
              name
              active
              createdAt
              updatedAt
              tenants {
                id
                name
                phone
                email
              }
              property {
                id
                address
              }
            }
          }
        `,
        variables: args,
      })
      .pipe(map((results) => results.data.listPropertyUnit));
  }

  public updatePropertyUnit(
    args: MutationUpdatePropertyUnitArgs,
  ): Observable<PropertyUnit> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation UpdatePropertyUnit(
            $data: PropertyUnitUpdateInput!
            $where: PropertyUnitWhereUniqueInput!
          ) {
            updatePropertyUnit(data: $data, where: $where) {
              id
              name
              active
              createdAt
              updatedAt
              tenants {
                id
                name
                phone
                email
              }
            }
          }
        `,
        variables: args,
        refetchQueries: ['WatchProperty'],
        awaitRefetchQueries: true,
      })
      .pipe(map((results) => results.data!.updatePropertyUnit));
  }

  public addTenant(
    unitId: string,
    tenant: Omit<Tenant, 'propertyUnit'>,
  ): Observable<PropertyUnit> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation AddTenant($data: TenantCreateInput!) {
            addPropertyUnitTenant(data: $data) {
              id
              name
              active
              createdAt
              updatedAt
              tenants {
                id
                name
                phone
                email
              }
            }
          }
        `,
        variables: {
          data: {
            ...tenant,
            propertyUnit: {
              connect: {
                id: unitId,
              },
            },
          } satisfies Omit<TenantCreateInput, 'messageCodes'>,
        },
      })
      .pipe(
        map((result) => {
          return result.data!.addPropertyUnitTenant;
        }),
      );
  }

  public deleteTenant(
    unitId: string,
    tenant: Tenant,
  ): Observable<PropertyUnit> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation DeleteTenant(
            $data: PropertyUnitUpdateInput!
            $where: PropertyUnitWhereUniqueInput!
          ) {
            updatePropertyUnit(data: $data, where: $where) {
              id
              name
              active
              createdAt
              updatedAt
              tenants {
                id
                name
                phone
                email
              }
            }
          }
        `,
        variables: {
          data: {
            tenants: {
              delete: [{ id: tenant.id }],
            },
          },
          where: {
            id: unitId,
          },
        } satisfies MutationUpdatePropertyUnitArgs,
      })
      .pipe(map((result) => result.data!.updatePropertyUnit));
  }

  /**
   * Create an authenticated link to the tenant hub for the given tenant
   */
  public createTenantHubLink(tenantId: string): Observable<string> {
    return this.rcApiService.http
      .post<{ url: string }>(
        `${environment.apiUrl}/property-unit/create-tenant-hub-link`,
        {
          tenantId,
        },
      )
      .pipe(map((result) => result.url));
  }
}
